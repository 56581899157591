import React from 'react'
import Main from './Components/Main'
import "./App.css"
import Sub from './Components/Sub'
import Grid from "./Components/Grid"
import Footer from './Components/Footer'

function App() {
  return (
    <div>

<Main/>
<Sub/>



  <Grid/>
  <Footer/>
    </div>
  )
}

export default App